/*
This enum contains the event names we track in Mixpanel.

Storing here ensures we have a high-level view of everything we're logging
and naming is consistent across the app.

When adding, make sure your event name follows the naming convention and
you include a comment explaining the outcome of your event.

All events are pulled from this sheet:
https://docs.google.com/spreadsheets/d/1Q1LRD8Y_lC_Omfyo2KPVIzMKNUoFdR6-04K0lhfp_Hk/edit?gid=0#gid=0
*/
export enum MixpanelEventName {
  // Navigation Events
  NAV_JOIN_CLICKED = "nav_join_clicked",
  NAV_SIGN_IN_CLICKED = "nav_sign_in_clicked",
  NAV_ABOUT_PHIA_CLICKED = "nav_about_phia_clicked",
  NAV_USER_LOGOUT_CLICKED = "nav_user_logout_clicked",
  NAV_MENU_CLICKED = "nav_menu_clicked",
  NAV_AVATAR_CLICKED = "nav_avatar_clicked",
  NAV_LOGO_CLICKED = "nav_logo_clicked",
  NAV_EXTENSION_AD_CLICKED = "nav_extension_ad_clicked",
  NAV_MENU_DROPDOWN_SELECTED = "nav_menu_dropdown_selected",
  NAV_SUBMIT_FEEDBACK_CLICKED = "nav_submit_feedback_clicked",
  BACK_ARROW_BUTTON_CLICKED = "back_arrow_button_clicked",
  SIGN_IN_MODAL_VIEWED = "sign_in_modal_viewed",
  SIGN_IN_MODAL_CLOSE_BTN_CLICKED = "sign_in_modal_close_btn_clicked",
  LOGIN_ERROR = "login_error",
  LOGIN_SUCCESS = "login_success",
  LOGOUT_ERROR = "logout_error",
  ERROR_PAGE_VIEW = "error_page_view",
  ERROR_PAGE_RELOAD = "error_page_reload",
  BACK_TO_TOP_CLICKED = "back_to_top_clicked",

  JOIN_PAGE_VISITED = "join_page_visited",
  JOIN_PAGE_CLOSED_WITHOUT_CLICK = "join_page_closed_without_click",
  // User Sign-In and Sign-Out Events
  SIGN_OUT_CLICKED = "sign_out_clicked",
  SIGN_OUT_CONFIRMED = "sign_out_confirmed",
  SIGN_OUT_CANCELLED = "sign_out_cancelled",
  LOGIN_WITH_GOOGLE_CLICKED = "login_with_google_clicked",
  LOGIN_WITH_APPLE_CLICKED = "login_with_apple_clicked",
  SIGN_UP_FROM_SIGN_IN_MODAL_CLICKED = "sign_up_from_sign_in_modal_clicked",
  SIGN_IN_FROM_REFERRAL_CLAIM_CLICKED = "sign_in_from_referral_claim_clicked",

  // Onboarding Events
  ONBOARDING_CONFIGURE_PROFILE_SAVE_CLICKED = "onboarding_configure_profile_save_clicked",
  ONBOARDING_CONFIGURE_PROFILE_SKIP_CLICKED = "onboarding_configure_profile_skip_clicked",
  ONBOARDING_CHOOSE_SIZES_SKIP_CLICKED = "onboarding_choose_sizes_skip_clicked",
  ONBOARDING_CHOOSE_SIZES_SAVE_CLICKED = "onboarding_choose_sizes_save_clicked",
  ONBOARDING_CHOOSE_PRICE_TIERS_SKIP_CLICKED = "onboarding_choose_price_tiers_skip_clicked",
  ONBOARDING_CHOOSE_PRICE_TIERS_SELECTED = "onboarding_choose_price_tiers_selected",
  ONBOARDING_CHOOSE_PRICE_TIERS_SAVE_CLICKED = "onboarding_choose_price_tiers_save_clicked",
  ONBOARDING_CHOOSE_BRANDS_SAVE_CLICKED = "onboarding_choose_brands_save_clicked",
  ONBOARDING_CHOOSE_BRANDS_SKIP_CLICKED = "onboarding_choose_brands_skip_clicked",
  ONBOARDING_START_SHOPPING_EXTENSION_AD_CLICKED = "onboarding_start_shopping_extension_ad_clicked",
  ONBOARDING_START_SHOPPING_CLICKED = "onboarding_start_shopping_clicked",
  ONBOARDING_GENDER_CHANGED = "onboarding_gender_changed",

  // Search Events
  SEARCH_BY_IMAGE_CANCEL = "search_by_image_cancel",
  SEARCH_BY_IMAGE_SUBMIT = "search_by_image_submit",
  SEARCH_BY_IMAGE_CHANGE_IMAGE_CLICKED = "search_by_image_change_image_clicked",
  SEARCH_BAR_CLEARED = "search_bar_cleared",
  SEARCH_BAR_CAMERA_ICON_CLICKED = "search_bar_camera_icon_clicked",
  SEARCH_BAR_EXPLORE_CLICKED = "search_bar_explore_clicked",
  SEARCH_BAR_SEARCH_CLICKED = "search_bar_search_clicked",
  SEARCH_BAR_TAG_REMOVED = "search_bar_tag_removed",
  SEARCH_OVERLAY_EDITORIAL_CLICKED = "search_overlay_editorial_clicked",
  SEARCH_OVERLAY_BRAND_CLICKED = "search_overlay_brand_clicked",
  SEARCH_OVERLAY_CLOSE_BUTTON_CLICKED = "search_overlay_close_button_clicked",
  SEARCH_OVERLAY_RECENT_SEARCH_CLICKED = "search_overlay_recent_search_clicked",
  SEARCH_OVERLAY_RECENT_SEARCH_REMOVED = "search_overlay_recent_search_removed",
  RECENT_SEARCH_ADDED = "recent_search_added",
  SEARCH_OVERLAY_POPULAR_SEARCH_CLICKED = "search_overlay_popular_search_clicked",
  SEARCH_BAR_CLICKED = "search_bar_clicked",
  SEARCH_OVERLAY_TRIGGERED = "search_overlay_triggered",

  // Filtering and Sorting Events
  FILTERS_CLICKED = "filters_clicked",
  SORT_BY_CLICKED = "sort_by_clicked",
  SORT_BY_SELECTED = "sort_by_selected",
  FILTERS_APPLY_CLICKED = "filters_apply_clicked",
  ADD_SIZES_FROM_FILTER_PANEL = "add_sizes_from_filter_panel",
  FILTERS_CLEARED = "filters_cleared",
  FILTER_PANEL_CLOSED = "filter_panel_closed",
  FILTER_GROUP_TOGGLED = "filter_group_toggled",
  USE_MY_SIZES_TOGGLED = "use_my_sizes_toggled",
  USE_MY_SIZES_TOGGLED_OUTSIDE_FILTER_PANEL = "use_my_sizes_toggled_outside_filter_panel",
  USE_MY_SIZES_TOGGLED_SIMILAR_PRODUCTS = "use_my_sizes_toggled_similar_products",
  USE_CUSTOM_PRICE_RANGE_TOGGLED = "use_custom_price_range_toggled",
  MANAGE_SIZES_FROM_FILTER_PANEL = "manage_sizes_from_filter_panel",
  FILTER_GROUP_SIZE_CATEGORY_CHANGED = "filter_group_size_category_changed",
  FILTER_GROUP_GENDER_CHANGED = "filter_group_gender_changed",
  LEARN_MORE_ABOUT_CONDITIONS_CLICKED = "learn_more_about_conditions_clicked",
  LEARN_MORE_ABOUT_CONDITIONS_MODAL_CLOSED = "learn_more_about_conditions_modal_closed",
  // Product Events
  PRODUCT_RESULT_SAVE_CLICKED = "product_result_save_clicked",
  PRODUCT_RESULT_CLICKED = "product_result_clicked",
  PRODUCT_RESULT_SHARED = "product_result_shared",

  PRODUCT_SHARE_CREATION_ERROR = "product_share_creation_error",
  PRODUCT_SHARE_VISITED = "product_share_visited",

  // @deprecated
  PRODUCT_THRIFT_IT_BUTTON_CLICKED = "product_thrift_it_button_clicked",

  PRODUCT_UNLOCK_RESALE_CLICKED = "product_unlock_resale_clicked",

  // NOTE: Deprecated with new search approach - use merchant link instead
  PRODUCT_AFFILIATE_LINK_CLICKED = "product_affiliate_link_clicked",
  PRODUCT_AFFILIATE_LINK_ERROR = "product_affiliate_link_error",

  // Merchant URLs
  MERCHANT_LINK_CLICKED = "merchant_link_clicked",
  MERCHANT_LINK_CREATION_ERROR = "merchant_link_creation_error",

  CAROUSEL_TOGGLED = "carousel_toggled",
  PRODUCT_RESULT_MODAL_VIEWED = "product_result_modal_viewed",
  // Product Events: Track Close Modal
  PRODUCT_RESULT_MODAL_CLOSED = "product_result_modal_closed",
  PRODUCT_RESULT_LOADED = "product_result_loaded",
  PRODUCT_RESULT_HOVERED = "product_result_hovered",
  PRODUCT_RESULT_SHOWN = "product_result_shown",
  PRODUCT_PAGE_VIEWED = "product_page_viewed",

  // Landing Page Events
  LANDING_PAGE_EXTENSION_AD_CLICKED = "landing_page_extension_ad_clicked",
  LANDING_PAGE_HERO_BANNER_BUTTON_CLICKED = "landing_page_hero_banner_button_clicked",
  LANDING_PAGE_SHOWCASED_BRAND_CLICKED = "landing_page_showcased_brand_clicked",
  LANDING_PAGE_FEATURED_EDIT_BUTTON_CLICKED = "landing_page_featured_edit_button_clicked",
  LANDING_PAGE_PRODUCT_ROW_VIEW_MORE_CLICKED = "landing_page_product_row_view_more_clicked",
  LANDING_PAGE_FEATURED_BRAND_TILE_CLICKED = "landing_page_featured_brand_tile_clicked",
  LANDING_PAGE_SHOWCASED_PARTNER_TILE_CLICKED = "landing_page_showcased_partner_tile_clicked",
  LANDING_PAGE_SHOWCASED_CATEGORY_CLICKED = "landing_page_showcased_category_clicked",
  NEWSLETTER_SIGN_UP_COMPLETED = "newsletter_sign_up_completed",
  LANDING_PAGE_SHOWCASED_PRODUCT_CLICKED = "landing_page_showcased_product_clicked",

  // Referral Events
  REFERRAL_CLAIMED = "referral_claimed",
  REFERRAL_CLAIM_ERROR = "referral_claim_error",
  REFERRAL_LINK_CREATION_BUTTON_CLICKED = "referral_link_creation_button_clicked",
  NAVIGATED_TO_GIVEAWAY_PAGE = "navigated_to_giveaway_page",

  // Contact Us Events
  CONTACT_US_SUBMIT_FEEDBACK_CLICKED = "contact_us_submit_feedback_clicked",
  CONTACT_US_CONTACT_SUPPORT_CLICKED = "contact_us_contact_support_clicked",
  CONTACT_US_CONTACT_SUPPORT_SUBMITTED = "contact_us_contact_support_submitted",
  FAQ_TOGGLED = "faq_toggled",

  // Beta Access Events
  BETA_ACCESS_ERROR_VIEWED = "beta_access_error_viewed",
  BETA_ACCESS_ERROR_GO_BACK_CLICKED = "beta_access_error_go_back_clicked",
  BETA_LANDING_PAGE_EMAIL_SUBMITTED = "beta_landing_page_email_submitted",
  SHARE_BETA_MODAL_CLOSED = "share_beta_modal_closed",
  BETA_ACCESS_ERROR_SUPPORT_EMAIL_CLICKED = "beta_access_error_support_email_clicked",

  // Page View Events
  PAGE_VIEW = "page_view",
  NO_RESULTS_FOUND_PAGE_VIEW = "no_results_found_page_view",

  // Uninstall Events
  UNINSTALL_FORM_SUBMITTED = "uninstall_form_submitted",
  UNINSTALL_REINSTALL_CLICKED = "uninstall_reinstall_clicked",
  FOUNDERS_CALENDAR_LINK_CLICKED = "founders_calendar_link_clicked",
  FEEDBACK_LINK_CLICKED = "feedback_link_clicked",
  UNINSTALL_REASON_TOGGLED = "uninstall_reason_toggled",

  // Category and Menu Events
  CATEGORY_PAGE_BREADCRUMB_CLICKED = "category_page_breadcrumb_clicked",
  CATEGORY_MENU_OVERLAY_PAGE_CLICKED = "category_menu_overlay_page_clicked",
  CATEGORY_MENU_FEATURED_PAGE_CLICKED = "category_menu_featured_page_clicked",
  VIEW_MORE_PRODUCTS = "view_more_products",
  CATEGORY_MENU_CLOSED_ON_MOBILE = "category_menu_closed_on_mobile",

  // User Profile Events
  CONFIGURE_USERNAME_CLICKED = "configure_username_clicked",
  PROFILE_SETTINGS_UPDATED = "profile_settings_updated",

  // Size Chart Events
  SIZE_CHART_CLOSED = "size_chart_closed",
  SIZE_CHART_CLICKED = "size_chart_clicked",
  SIZE_CHART_GENDER_CHANGED = "size_chart_gender_changed",
  SIZE_CHART_CATEGORY_CHANGED = "size_chart_category_changed",

  // Footer and Support Events
  FOOTER_LINK_CLICKED = "footer_link_clicked",
  SUPPORT_LINK_CLICKED = "support_link_clicked",
  ABOUT_US_EXPLORE_CLICKED = "about_us_explore_clicked",

  // User Preferences Events
  USER_PREFERENCES_OPTION_TOGGLED = "user_preferences_option_toggled",
  USER_PREFERENCES_SAVE_BUTTON_CLICKED = "user_preferences_save_button_clicked",
  USER_PREFERENCES_CANCEL_BUTTON_CLICKED = "user_preferences_cancel_button_clicked",
  PROFILE_MANAGE_DROPDOWN_CLICKED = "profile_manage_dropdown_clicked",
  PROFILE_MANAGE_CLICKED = "profile_manage_clicked",
  PRODUCT_DETAILS_SHOW_MORE = "product_details_show_more",
  PRODUCT_DETAILS_SHOW_LESS = "product_details_show_less",
  PRODUCT_DETAILS_VIEW_MORE_CLICKED = "product_details_view_more_clicked",
  PRODUCT_DETAILS_ALT_PRODUCT_CLICKED = "product_details_alt_product_clicked",
  PRODUCT_ALTERNATIVES_LOADED = "product_alternatives_loaded",

  SHOW_MORE_BRANDS = "show_more_brands",
  SHOW_LESS_BRANDS = "show_less_brands",
  SHOW_MORE_PARTNERS = "show_more_partners",
  SHOW_LESS_PARTNERS = "show_less_partners",
  FILTERS_SELECTED = "filters_selected",

  LANDING_PAGE_SECTION_VIEWED = "landing_page_section_viewed",
  CATEGORY_MENU_PILL_HOVER = "category_menu_pill_hover",
  CATEGORY_MENU_PILLS_SHOWN = "category_menu_pills_shown",

  EDITORIAL_PAGE_VIEWED = "editorial_page_viewed",
  CATEGORY_PAGE_VIEWED = "category_page_viewed",
  PARTNER_PAGE_VIEWED = "partner_page_viewed",
  BRAND_PAGE_VIEWED = "brand_page_viewed",

  ONBOARDING_SUGGEST_BRAND = "onboarding_suggest_brand",
  FEEDBACK_SUBMITTED = "feedback_submitted",
  FEEDBACK_CLOSED = "feedback_closed",
  SEARCH_FEEDBACK_SUBMITTED = "search_feedback_submitted",
  SEARCH_FEEDBACK_CLOSED = "search_feedback_closed",
  SEARCH_FEEDBACK_MODAL_OPEN = "search_feedback_modal_open",
  BETA_FEEDBACK_CLOSED = "beta_feedback_closed",
  BETA_LEAVE_FEEDBACK_CLICKED = "beta_leave_feedback_clicked",
  BETA_BUTTON_CLICKED_IN_NAV = "beta_button_clicked_in_nav",

  COLLECTION_TEASER_CLICKED = "collection_teaser_clicked",
  COLLECTION_TEASER_SUBMITTED = "collection_teaser_submitted",
  COLLECTION_TEASER_CLOSED = "collection_teaser_closed",

  LOAD_PRODUCTS_FAILED = "load_products_failed",
  SEARCH_FAILED = "search_failed",
  SCRAPE_FAILED = "scrape_failed",
  DEAD_IMAGE_VISIBLE = "dead_image_visible",
  BUG_SUBMITTED = "bug_submitted",

  IN_APP_BROWSER_USED = "in_app_browser_used",

  LINK_PASTING_SUGGEST_WEBSITE = "link_pasting_suggest_website",

  SCRAPED_PRODUCT_SIDEBAR_TOGGLED = "scraped_product_sidebar_toggled",
  SCRAPED_PRODUCT_VISITED = "scraped_product_visited",
  SCRAPED_PRODUCT_ADDITIONAL_IMAGE_CLICKED = "scraped_product_additional_image_clicked",
  RESALE_VALUE_DISPLAYED = "resale_value_displayed",
  RESALE_VALUE_BUTTON_CLICKED = "resale_value_button_clicked",

  SEARCH_SHARE_CLICKED = "search_share_clicked",
  SHARED_SEARCH_VISITED = "shared_search_visited",

  // Direct Link Generation
  PRODUCT_DIRECT_LINK_GENERATED = "product_direct_link_generated",
  PRODUCT_DIRECT_LINK_ERROR = "product_direct_link_error",

  SIGNED_OUT_SORT_BUTTON_CLICKED = "signed_out_sort_button_clicked",
  SIGNED_OUT_FILTER_BUTTON_CLICKED = "signed_out_filter_button_clicked",
}

/*
This enum contains the event names we track in CustomerIO.

Storing here ensures we have a high-level view of everything we're logging
and naming is consistent across the app.

When adding, make sure your event name follows the naming convention and
you include a comment explaining the outcome of your event.
*/
export enum CustomerIoEventName {
  // The referee has entered their email and signed up on the waitlist.
  REFERRAL_CLAIMED = "referral_claimed",
  NEWSLETTER_SIGNUP = "newsletter_signup",
}

export type LogEventProperties = Record<
  string,
  // TODO: investigate if we want to set `any` to this
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  string | boolean | number | string[] | Record<string, any> | undefined | null
>;

export enum SigninModalSource {
  NavMenu = "nav_menu",
  Searchbar = "searchbar",
  AddSizes = "add_sizes",
  FilterAndSortButton = "filter_and_sort_button",
  GiveawayButton = "giveaway_button",
  ShareSearch = "share_search",
}
